import { inject } from "mobx-react";
import { Fragment } from "react";

import { FeedResultFragment } from "@ihr-radioedit/inferno-webapi";
import { reverseRoute } from "@inferno/renderer-shared-core";
import { Heading, MagicLink } from "../../../../core/ui";
import { CoastFeedInTheNewsHomepageItem } from "./CoastFeedInTheNewsHomepageItem.component";
import type { Store } from "@inferno/renderer-shared-core";

interface CoastFeedInTheNewsHomepageProps {
  content: FeedResultFragment[] | null;
  store?: Store;
  title: string;
}

export const CoastFeedInTheNewsHomepage = inject("store")(
  ({ store, content, title }: CoastFeedInTheNewsHomepageProps) => {
    if (!store || !content?.length) {
      return null;
    }

    const moreLink = reverseRoute(store.site, "in_the_news");

    return (
      <Fragment>
        <Heading level={2}>{title}</Heading>

        <div className="feed-cards">
          {content.map(item => (
            <CoastFeedInTheNewsHomepageItem key={item.id} item={item} />
          ))}
        </div>

        {moreLink ? (
          <div className="more-container">
            <MagicLink className="more" to={moreLink}>
              More &raquo;
            </MagicLink>
          </div>
        ) : null}
      </Fragment>
    );
  },
);

export default CoastFeedInTheNewsHomepage;
