import { inject } from "mobx-react";

import type { FeedResultFragment } from "@ihr-radioedit/inferno-webapi";
import { MagicLink } from "../../../../core/ui";
import type { Store } from "@inferno/renderer-shared-core";
import { isPublishRecord } from "@inferno/renderer-shared-core";

interface CoastFeedInTheNewsHomepageItemProps {
  item: FeedResultFragment;
  store?: Store;
}

export const CoastFeedInTheNewsHomepageItem = inject("store")(
  ({ item, store }: CoastFeedInTheNewsHomepageItemProps) => {
    if (
      !store ||
      !isPublishRecord(item.record) ||
      !item.record.payload.fields?.source?.value ||
      !item.record.payload.fields?.source_url?.value
    ) {
      return null;
    }

    const { slug, payload, summary } = item.record;

    return (
      <div className="coast-feed-item">
        <MagicLink className="item-title" to={payload.fields.source_url.value} context={slug}>
          {summary.title}
        </MagicLink>

        <em>&#8212;&nbsp;{payload.fields.source.value}</em>
      </div>
    );
  },
);

export default CoastFeedInTheNewsHomepageItem;
